import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official"
import { compact, has, merge, remove, startCase } from "lodash"
import moment from "moment"
import { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Table } from "reactstrap"

import classNames from "classnames"
import { AttributionChart, AttributionData, AttributionPerformancePeriod, AttributionTable, AttributionTableRow, ComponentOverrideSettings, ComponentPeriodType, ComponentType, ManagerPerformancePeriodicity, NewReportAttributionDataQuery, ReportAttributionDataQuery, ReportAttributionDataQueryVariables, ReportAttributionFetchFragment, useListSimpleQuery, useNewReportAttributionDataQuery, useReportAttributionDataQuery } from "../../../__generated__/graphql"
import { DATE_DISPLAY_FORMAT } from "../../../helpers/constant"
import { excludePropertyArray } from "../../../helpers/object"
import { convertComponentForMutation } from "../../../helpers/report"
import { ApprovalsReportDisplay } from "../Shared/ReportApprovals"
import { AggregatedComponentProps, AggregatedExportSettingsProps, BaseComponent, ErrorComponent, FootnotableComponent, IndividualComponentProps, LoadingComponent, ReportDisplayType, TemplateComponent } from "../Shared/ReportComponent"
import { AggregatedReportEditProps, ReportEditField, ReportEditHeading, ReportEditInfoFields, ReportEditInstances, SimpleReportEdit } from "../Shared/ReportEdit"



interface HoveredCell {
  rowName: string
  columnName: string
}

const Attribution: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportAttributionFetchFragment>
      expectedTypename={"AttributionSettings"}
      reactComponent={AttributionDisplayWrapper}
      {...props}
    />
  )
}

const fetchDisplayData = (data: NewReportAttributionDataQuery | ReportAttributionDataQuery, view: ReportDisplayType, usePreview: boolean): AttributionData => {
  if(has(data, 'componentPreviewData')) {
    const previewData = data as NewReportAttributionDataQuery
    return previewData?.componentPreviewData as AttributionData
  }

  const previewData:ReportAttributionDataQuery = data as ReportAttributionDataQuery

  if ((view === ReportDisplayType.Draft || view === ReportDisplayType.External) && previewData?.components?.[0]?.draftData) {
    return previewData?.components?.[0]?.draftData as AttributionData
  } else if (usePreview && previewData?.components?.[0]?.previewData) {
    return previewData?.components?.[0]?.previewData as AttributionData
  }

  return previewData.components?.[0]?.liveData as AttributionData
}

const AttributionDisplayWrapper: React.FC<IndividualComponentProps<ReportAttributionFetchFragment>> = ({component, auth, settings, selected, handleSelect, editMode, sectionNumber, componentNumber, report, setEditedDraftLayout, editedDraftLayout, setSelectedComponentId, view, setReportState, reportState: inState, hideSingleExport, clientId, overwriteDate, portfolioId }) => {
  const name = component.name || ""
  const componentId = component.id

  const usedDate = overwriteDate || settings.date
  const asOfDate = moment(overwriteDate || settings.date).format(DATE_DISPLAY_FORMAT)

  const [initSettings, setInitSettings] = useState(settings)
  const [hoveredCell, setHoveredCell] = useState<HoveredCell | null>(null)

  useEffect(()=>{
    if(editMode === false && initSettings !== settings) {
      setInitSettings(settings)
    }
  }, [editMode]) // eslint-disable-line react-hooks/exhaustive-deps
  // Only update this when editMode changes, otherwise the options modal will overwrite the initSettings

  const usePreview = (initSettings !== settings)
  const useNew = component.id < 1
  let usedSettings = {}

  console.log({ settings, usePreview, useNew })

  if(usePreview || useNew) {
    let convertedSettings = excludePropertyArray(convertComponentForMutation(settings, ComponentType.Attribution), ["__typename"])
    console.log({ convertedSettings})
    usedSettings = {
      attribution: {
        ...convertedSettings,
      }
    }
  }

  const queryVariables: ReportAttributionDataQueryVariables = {
    ids: [component.id],
    liveView: (view === ReportDisplayType.Live) && !usePreview,
    draftView: (view === ReportDisplayType.Draft || view === ReportDisplayType.External) && !usePreview,
    preview: usePreview,
    settings: usedSettings,
  }

  const { loading: newLoading, data: newData, error: newError } = useNewReportAttributionDataQuery({
    variables: queryVariables,
    errorPolicy: "all",
    skip: !useNew
  })

  const { loading: baseLoading, data: baseData, error: baseError } = useReportAttributionDataQuery({
    variables: queryVariables,
    errorPolicy: "all",
    skip: useNew
  })

  let loading, error
  if(useNew) {
    loading = newLoading
    error = newError
  } else {
    loading = baseLoading
    error = baseError
  }

  if(loading) return <LoadingComponent name={name} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
  if(error) return <ErrorComponent name={name} error={error?.message} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>

  const data = useNew ? newData : baseData

  if (data) {
    console.log({ data })
    const displayedData = fetchDisplayData(data, view, usePreview)
    const tableData = displayedData?.table
    const chartData = displayedData?.chart
    let tooltipProps = {}

    return (
      <TemplateComponent
        name={name}
        componentTypeName='Attribution'
        rightText={`As of ${asOfDate}`}
        selected={selected}
        onClick={handleSelect}
        tooltipProps={tooltipProps}
        editMode={editMode}
        sectionNumber={sectionNumber}
        report={report}
        setEditedDraftLayout={setEditedDraftLayout}
        editedDraftLayout={editedDraftLayout}
        componentNumber={componentNumber}
        componentId={componentId}
        setSelectedComponentId={setSelectedComponentId}
        view={view}
        auth={auth}
        portfolioId={portfolioId}
        hideExport={hideSingleExport}
      >
        {/* <PerformanceComparisonOptionsModal
          settings={settings}
          optionModalOpen={optionModalOpen}
          setOptionModalOpen={setOptionModalOpen}
          report={report}
          updateSettings={updateSettings}
          componentId={componentId}
          view={view}
        /> */}
        <Row className="no-gutters">
          {/* <Col sm="12"> // Removed for the time being until new components are created.
            <PerformanceComparisonSupporting
              settings={settings}
              portfolio={portfolio}
              dcPortfolio={dcPortfolio}
            />
          </Col> */}
          { displayedData && chartData && (
            <AttributionGraph
              editMode={editMode}
              chartData={chartData}
            />
          )}
        </Row>
        <Row className="no-gutters">
          <Col sm="12">
            { displayedData && tableData && (
              <AttributionDataTable
                tableData={tableData}
              />
            )}
          </Col>
        </Row>
      </TemplateComponent>
    )
  }

  return <ErrorComponent name={name} error={"No data returned"} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>

}

const AttributionGraph: React.FC<{editMode: boolean, chartData: AttributionChart }> = ({ editMode, chartData }) => {
  const graphRef = useRef<{ chart: Highcharts.Chart; container: React.RefObject<HTMLDivElement>; }>(null)

  const chartOptions = merge(JSON.parse(chartData.config || '{}'))
  const graph = useMemo(() => {
    return (
      <Col sm="12">
        <div className="form-section-title headline underline small-font-size mb-0 pb-0">{chartData.title}</div>
        <HighchartsReact
          ref={graphRef}
          options={chartOptions}
          highcharts={Highcharts}
          containerProps={{ style: { height: "400px"  }}}
        />
      </Col>
    )
  }, [chartOptions, chartData.title])

  return graph
}

const AttributionDataTable: React.FC<{tableData: AttributionTable}> = ({tableData}) => {
  const columns = tableData.columns || []
  const tableRows = compact(tableData.rows) || []

  return (
    <Col sm="12">
      <div className="form-section-title headline underline small-font-size mb-0 pb-0">{tableData.title}</div>

      <Table className="report-table-attribution-table">
        <thead>
          <tr>
          {
            columns.map((column, idx) => (
              <td className="pl-0 text-center" key={`attribution-column-header-${idx}`}>
                <FootnotableComponent>
                  { column }
                </FootnotableComponent>
              </td>
            ))
          }
          </tr>
        </thead>
        <tbody>
          {
            tableRows.map((row: AttributionTableRow, idx) => (
              <tr
                className={classNames({
                  "report-tr-title": true,
                  "report-tr-total": row.total,
                })}
                key={`attribution-row-${idx}`}
              >
                {
                  row.columnData?.map((cell, idx) => (
                    <td className="pl-0 text-center" key={`attribution-cell-${idx}`}>
                      { cell?.formattedValue || ' - '}
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Col>

  )

}

const AttributionReportingPeriodFields:ReportEditField[] = [
  {
    property: "attributionReportingPerformancePeriod",
    label: "",
    type: "radio",
    options: [{
      code: "Trailing",
      value: "Trailing",
    },{
      code: "StartingOn",
      value: "Starting On",
    }],
    subClasses: {
      inputWrapperClasses: "col-sm-12 radio-input-with-divider mt-1 mb-2 w-100",
    },
  }
]

const AttributionPerformanceFields = [
  {
    property: "performancePeriodicity",
    label: "Returns used",
    type: "select",
    optionSource: "ManagerPerformancePeriodicity",
    required: true
  },
  {
    property: "attributionPerformanceType",
    label: "Performance type",
    type: "select",
    optionSource: "FeeType",
    required: true,
  }
]

const AttributionAdditionalReportingFields:ReportEditField[] = [
  {
    property: "lineChart",
    label: "Line Chart",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

const AttributionEditFootnoteFields:ReportEditField[] = [
  {
    property: "showTargetFootnote",
    label: "Benchmark",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

const getColumnsByPeriod = (settings: ReportAttributionFetchFragment):ReportEditField[] => {
  console.log('attributionReportingPerformancePeriod', settings.attributionReportingPerformancePeriod)
  switch (settings.attributionReportingPerformancePeriod) {
    case AttributionPerformancePeriod.Trailing:
      let newOptions = Object.values(ComponentPeriodType).map((period) => ({
        code: period,
        value: startCase(period)
      }))

      if (settings.performancePeriodicity === ManagerPerformancePeriodicity.Quarterly) {
        remove(newOptions, (option) => option.code === 'lastMonth')
      }

      return [{
        property: "attributionPeriod",
        label: "Period",
        type: "select",
        options: newOptions,
      }]

    case AttributionPerformancePeriod.StartingOn:
      let subType = 'month'
      if (settings.performancePeriodicity === ManagerPerformancePeriodicity.Quarterly) {
        subType = 'quarter'
      }

      return [
        {
          property: "startDate",
          label: "Start on and include",
          type: "date",
          subtype: subType,
          required: true,
        }
      ]
    default:
      return []
  }
}


export const AttributionEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, reportId}) => {
  const settings = component.draftSettings as ReportAttributionFetchFragment
  const { data: listData } = useListSimpleQuery({
    variables: {id: settings.list?.id || 0},
    skip: !settings || !settings.list?.id,
    errorPolicy: "all",
  })
  if(!settings){
    return(
      <></>
    )
  }

  console.log({ settings, compsettings: component.draftSettings })

  let reportingPeriodColumns = [...AttributionReportingPeriodFields, ...getColumnsByPeriod(settings)]

  console.log('edit settings', settings)
  const ownedComponent = component.owner?.id === user?.person?.id

  let benchmarkOptions = settings?.clientPortfolio?.performanceTargetMap?.map((targetMap) => (
    {
      code: targetMap?.target?.targetId || null,
      value: targetMap?.target?.name || '',
    }
  ))
  console.log({ benchmarkOptions})
  let infoColumns = [...ReportEditInfoFields(handleInputChange), {
    property: "target.targetId",
    label: "Benchmark",
    type: "select",
    options: benchmarkOptions
  }]

  return (
    <>
      <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={"Attribution"}
        componentType={ComponentType.Attribution}
        reportId={reportId}
        handleInputChange={handleInputChange}
      />
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <ReportEditInstances component={component}/>
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      />
      <SimpleReportEdit
        name={"Performance"}
        retractable={true}
        fields={AttributionPerformanceFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"Additional Reporting"}
        retractable={true}
        fields={AttributionAdditionalReportingFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"Reporting Period"}
        retractable={true}
        fields={reportingPeriodColumns}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"footnotes"}
        retractable={true}
        fields={AttributionEditFootnoteFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
    </>
  )
}

export const AttributionExportSettings = (props: AggregatedExportSettingsProps): ComponentOverrideSettings =>  {
  // const { component } = props
  // const reportState = props.reportState as componentState
  // const tab = reportState?.tab
  // const selectedDrillDown = reportState?.selectedDrillDown

  // return {PerformanceComparison: [
  //   {
  //     componentId: component?.id,
  //     selectedDate: tab?.value,
  //     drillDownGroup: parseInt(selectedDrillDown || "") || undefined
  //   }
  // ]}
  return {}
}

export default Attribution