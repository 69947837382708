import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { compact, first, get, intersection, isEqual, kebabCase, set, sortBy, without } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { UncontrolledTooltip } from 'reactstrap/lib'

import { AssetDistributionComponentOverrideSettings, AssetDistributionPeriod, AssetDistributionRowFragment, ClientPortfolioDetailComponentFragment, ColumnHeader, ComponentExportType, ComponentOverrideSettings, ComponentType, LayoutSectionType, ReportAssetDistributionDataQueryVariables, ReportAssetDistributionFetchFragment, useListSimpleQuery, useNewAssetDistributionDataQuery, useReportAssetDistributionDataQuery } from '../../../__generated__/graphql'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import { excludePropertyArray } from '../../../helpers/object'
import { convertComponentForMutation, getAllAssetDistributionRowIds } from '../../../helpers/report'
import { useStoredTab } from '../../../helpers/usehook'
import { ApprovalsReportDisplay } from '../Shared/ReportApprovals'
import { AggregatedComponentProps, AggregatedExportSettingsProps, BaseComponent, ErrorComponent, FootnotableComponent, FootnoteLink, IndividualComponentProps, LoadingComponent, ReportDisplayType, ReportTab, TemplateComponent, ToolbarProps, getPortfolioRowLinks } from '../Shared/ReportComponent'
import { AdditionalReportingEdit, AggregatedReportEditProps, ReportEditField, ReportEditHeading, ReportEditInfoFields, ReportEditInfoTotalField, ReportEditInstances, SimpleReportEdit } from '../Shared/ReportEdit'

export const AssetDistributionIcon:IconProp = "table"

const AssetDistribution: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportAssetDistributionFetchFragment>
      expectedTypename={"AssetDistribution"}
      reactComponent={AssetDistributionDisplay}
      {...props}
    />
  )
}

// Used to store state that must be shared with export
type componentState = {
  tab?: ReportTab
  openItems?: number[]
}

export type AssetDistributionRowWithChildren = AssetDistributionRowFragment & {children?: (AssetDistributionRowWithChildren | null)[] | null}

const AssetDistributionDisplay: React.FC<IndividualComponentProps<ReportAssetDistributionFetchFragment>> = ({component, auth, settings, selected, handleSelect, editMode, sectionNumber, report, setEditedDraftLayout, editedDraftLayout, componentNumber, setSelectedComponentId, view,  setReportState, reportState: inState, hideSingleExport, clientId, overwriteDate, portfolioId}) => {
  const [initSettings, setInitSettings] = useState(settings)
  const reportState = inState as componentState

  const name = component.name || ""
  const usedDate = overwriteDate || settings.date
  const currentDate = moment(usedDate, DATE_API_FORMAT)

  const primaryTab = {label: currentDate.format("[Q]Q YYYY"), value: usedDate, primary: true} as ReportTab
  const tabs = sortBy([primaryTab, ...(settings.monthlyOptions?.dates?.map((date) => ({label: moment(date).format("MMM YYYY"), value: date})) || [])] as ReportTab[], (tab) => (tab.primary ? 0 : 1) - moment(tab.value).valueOf() )
  const mostRecentTab = tabs[0]

  const tab = reportState?.tab || mostRecentTab

  const asOfDate = moment(tab.value).format(DATE_DISPLAY_FORMAT)
  const searchDate = tab?.value || usedDate
  const usePreview = (initSettings !== settings)
  const useNew = component.id < 1


  const {fetchTab, storeTab} = useStoredTab(component.id, tabs)

  useEffect(() => {
    const storedTab = fetchTab()
    if (storedTab && storedTab.value !== usedDate) {
      setReportState({ tab: storedTab })
    } else {
      setReportState({tab: mostRecentTab})
    }
    setReportState({openItems: []})
  }, [])

  const setTab = (value:any) => {
    storeTab(value)
    setReportState({ tab: value })
  }

  useEffect(() => {
    if (!tabs.some((t) => t.value === searchDate) || !settings.monthlyOptions?.show) {
      setTab(mostRecentTab)
    }
  }, [settings])


  useEffect(()=>{
    if(editMode === false && initSettings !== settings) setInitSettings(settings)
  }, [editMode])

  let usedSettings = {}
  if (usePreview || useNew) {
    let convertedSettings = excludePropertyArray(convertComponentForMutation(settings, ComponentType.AssetDistribution), ["__typename"])

    usedSettings = {
      assetDistribution: {...convertedSettings, date: searchDate}
    }

    if (!tab.primary) {
      set(usedSettings, "assetDistribution.period", AssetDistributionPeriod.Monthly)
    }
  }

  const openItems = reportState?.openItems || []
  const setOpenItems = (value:number[]) => {
    setReportState({openItems: value})
  }

  const queryVariables:ReportAssetDistributionDataQueryVariables = {
    ids: [component.id],
    liveView: (view === ReportDisplayType.Live) && !usePreview,
    draftView: (view === ReportDisplayType.Draft) && !usePreview,
    preview: usePreview,
    settings: usedSettings,
  }

  if (!tab.primary) {
    queryVariables.dates = {startDate: moment(searchDate).subtract(1, 'month').endOf('month').format(DATE_API_FORMAT), endDate: searchDate }
  }

  const { loading: baseLoading, data: baseData, error: baseError } = useReportAssetDistributionDataQuery({
    variables: queryVariables,
    skip: useNew,
  })

  const { loading: newLoading, data: newData, error: newError } = useNewAssetDistributionDataQuery({
    variables: {settings: usedSettings},
    skip: !useNew,
  })

  let loading, error
  if(useNew) {
    loading = newLoading
    error = newError
  } else {
    loading = baseLoading
    error = baseError
  }

  if (loading) return <LoadingComponent name={name} rightText={`As of ${asOfDate}`} tabs={settings?.monthlyOptions?.show ? tabs : undefined} currentTab={tab} onTabChange={(tab) => setTab(tab)} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
  if (error) return <ErrorComponent name={name} error={error?.message} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>

  if (baseData || newData) {
    let tooltipProps:ToolbarProps = {}

    if(componentNumber === -1 && component.exportTypes?.includes(ComponentExportType.PPTX)) {
      let exportSettings = {
        componentId: component?.id,
        selectedDate: tab?.value,
        expandedRows: openItems.map((id) => id.toString()),
      } as AssetDistributionComponentOverrideSettings

      if(!tab?.primary){
        exportSettings["assetDistributionPeriod"] = AssetDistributionPeriod.Monthly
      }

      tooltipProps['exportOptions'] = {
        name: name,
        slides: [{
          title: name,
          sections: [{
            components: [component.id],
            type: LayoutSectionType.SingleColumnSection
          }]
        }],
        settings: {
          live: view === ReportDisplayType.Live,
          footerName: report?.exportSettings?.footerName || report?.client?.name,
          componentOverrideSettings: {
            assetDistribution: [
              exportSettings
            ]
          }
        }
      }
    }

    if (componentNumber === -1 && component.exportTypes?.includes(ComponentExportType.XLSX)) {
      tooltipProps['exportExcelOptions'] = {
        name: name,
        componentId: component.id,
        settings: {
          live: view === ReportDisplayType.Live,
          componentOverrideSettings: {
            assetDistribution:
            {
              selectedDate: tab.primary ? undefined : tab.value,
              expandedRows: openItems.map((id) => id.toString()),
            }
          }
        }
      }
    }

    let displayedData = baseData?.components?.[0]?.liveData
    if(useNew && newData?.componentPreviewData) {
      displayedData = newData?.componentPreviewData
    } else if (view === ReportDisplayType.Draft && baseData?.components?.[0]?.draftData) {
      displayedData = baseData?.components?.[0]?.draftData
    } else if (usePreview && baseData?.components?.[0]?.previewData) {
      displayedData = baseData?.components?.[0]?.previewData
    }

    if (displayedData?.__typename !== "AssetDistributionData") {
      return <ErrorComponent name={name} error={"Invalid Table Data."} rightText={`As of ${asOfDate}`} selected={selected} onClick={handleSelect} setEditedDraftLayout={setEditedDraftLayout}/>
    }

    const allOpenableItems = getAllAssetDistributionRowIds(compact(displayedData.rows as AssetDistributionRowWithChildren[]))

    const areAllExpanded = isEqual(intersection(allOpenableItems, openItems), allOpenableItems)

    return (
      <TemplateComponent
        name={name}
        componentTypeName='Asset Distribution'
        rightText={`As of ${asOfDate}`}
        selected={selected}
        onClick={handleSelect}
        tooltipProps={tooltipProps}
        tabs={settings?.monthlyOptions?.show ? tabs : undefined}
        currentTab={tab}
        onTabChange={(tab) => setTab(tab)}
        editMode={editMode}
        sectionNumber={sectionNumber}
        report={report}
        setEditedDraftLayout={setEditedDraftLayout}
        editedDraftLayout={editedDraftLayout}
        componentNumber={componentNumber}
        componentId={component.id}
        setSelectedComponentId={setSelectedComponentId}
        view={view}
        auth={auth}
        hideExport={hideSingleExport}
        portfolioId={portfolioId}
      >
        <Table hover className={"report-table"}>
          <thead>
            <tr className="row-border-olive-100">
              <th className="text-left">
                Portfolio
                <div
                  className="fake-link d-inline-block ml-2"
                  onClick={() =>
                    areAllExpanded
                      ? setOpenItems([])
                      : setOpenItems(allOpenableItems)
                  }
                >
                  {areAllExpanded ? "Collapse All" : "Expand All"}
                </div>
              </th>
              { displayedData?.columns?.map((column, idx) => (
                <th className="text-right" key={`${kebabCase(column?.text || '')}-${idx}-header`}>
                  <div className="d-inline-flex align-items-center tooltip-icon">
                    <span dangerouslySetInnerHTML={{ __html: column?.text ? column.text.replace(/\n/, '<br />') : '' }} />
                    { column?.tooltip && (
                      <>
                      <FontAwesomeIcon
                        icon={"question-circle" as IconName}
                        size="sm"
                        className={`ml-1`}
                        id={`${kebabCase(column?.text || '')}-toolip`}
                      />
                        <UncontrolledTooltip placement='top' target={`${kebabCase(column?.text || '')}-toolip`}>
                          {column?.tooltip}
                        </UncontrolledTooltip>
                      </>
                    )}

                  </div>

                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {compact(displayedData?.rows).map((row: AssetDistributionRowWithChildren, idx)=> {
              return (
                <AssetDistributionRow
                  key={row.rowId}
                  row={row}
                  component={component}
                  openItems={openItems}
                  setOpenItems={setOpenItems}
                  depth={0}
                  settings={settings}
                  searchDate={searchDate}
                  reportId={report?.id}
                  clientId={clientId}
                  view={view}
                  columns={compact(displayedData?.columns)}
                  planId={first(report?.plans)?.id}
                />
              )
            })}
          </tbody>
        </Table>
      </TemplateComponent>
    )
  }

  return <ErrorComponent name={name} error={"No data returned"} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
}

interface AssetDistributionRowProps {
  openItems: number[]
  setOpenItems: (value:number[]) => void
  row: AssetDistributionRowWithChildren
  depth: number
  settings: ReportAssetDistributionFetchFragment
  searchDate: string
  component: ClientPortfolioDetailComponentFragment
  reportId?: number
  planId?: number
  clientId?: number
  view: ReportDisplayType
  columns: ColumnHeader[]
}

const AssetDistributionRow: React.FC<AssetDistributionRowProps> = (props) => {
  const { row, openItems, setOpenItems, depth, settings, searchDate, component, reportId, planId, clientId, view, columns } = props

  const { rowId, total, footnote, productId } = row
  const hasSubgroup = (row.children?.length || 0) > 0
  const isOpen = openItems.includes(parseInt(rowId, 10))

  let links: FootnoteLink[] = []
  if(!row.header){
    links = getPortfolioRowLinks({componentId: rowId, reportId: reportId, planId, clientId, view, productId: productId || undefined })
  }

  const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(!hasSubgroup) return
    if(isOpen){
      setOpenItems(without(openItems, parseInt(rowId, 10)))
    } else {
      setOpenItems(compact([...openItems, parseInt(rowId, 10)]))
    }
  }

  const errors = compact([row.error])
  const showErrors = view !== ReportDisplayType.Live && errors.length > 0

  return (
    <>
      <tr className={classNames(
        "report-tr-title",
        {
          "report-tr-subgroup": depth > 0,
          "report-tr-total": total
        })}
      >
        <td className={classNames("text-left")} onClick={handleExpand}>
          <div className={classNames("report-table-item", {"has-subgroup": hasSubgroup})}>
            <div style={{marginLeft: depth * 10}} className="d-flex">
              {(depth !== 0 || hasSubgroup) &&
                <div className={`report-table-caret report-table-depth-${depth} `}>
                  {hasSubgroup &&
                    <FontAwesomeIcon
                      icon={isOpen ? "caret-down" : "caret-right"}
                      size="sm"
                    />
                  }
                </div>
              }
              <div className="d-flex">
                <div className={classNames("report-table-title", {"report-table-total": total})}>
                  <FootnotableComponent
                    bareFootnote={footnote}
                    showFootnote={settings.showFootnote}
                    selectedDate={searchDate}
                    errors={errors}
                    showErrors={showErrors}
                    links={links}
                    linkClasses={depth === 0 ? ["top-level-header"] : []}
                  >
                    {row.name}
                  </FootnotableComponent>
                </div>
              </div>
            </div>
          </div>
        </td>
        {columns?.map((column, idx) => {
          const colData = get(row, ['columnData',idx], null)

          if (!colData) {
            return <td key={`row-${rowId}-col-${idx}`}></td>
          }

          return (
            <td className={classNames("text-right", {'background-error': idx === 0 && showErrors})} key={`row-${rowId}-col-${idx}`}>
              {colData?.formattedValue || ' - '}
            </td>
          )
        })}
      </tr>
      {isOpen && hasSubgroup && compact(row?.children).map((row: AssetDistributionRowWithChildren, idx) => {
        return(
          <AssetDistributionRow
            key={row.rowId}
            row={row}
            openItems={openItems}
            component={component}
            setOpenItems={setOpenItems}
            depth={depth + 1}
            settings={settings}
            searchDate={searchDate}
            reportId={reportId}
            clientId={clientId}
            view={view}
            columns={columns}
            planId={planId}
          />
        )
      })}
    </>
  )
}


const AssetDistributionTableFields = (settings: ReportAssetDistributionFetchFragment):ReportEditField[] => {
  return [
    {
      property: "assetDistributionPeriod",
      label: "Time span",
      type: "select",
      optionSource: "AssetDistributionPeriod",
      optionFilterRule: {"SemiAnnual": true, "Quarterly": true, "Annually": true},
      required: true,
    },
    {
      property: "showWeight",
      label: "Weight percent",
      type: "checkbox",
      subtype: "show",
      required: true,
    },
    {
      property: "showNNI",
      label: "Net Cash Activity",
      type: "checkbox",
      subtype: "show",
      required: true,
    },
    {
      property: "showParticipants",
      label: "Participants",
      type: "checkbox",
      subtype: "show",
      required: true,
    }
  ]
}

const AssetDistributionFootnoteFields:ReportEditField[] = [
  {
    property: "showFootnote",
    label: "Portfolio",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

export const AssetDistributionEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, reportId}) => {
  const settings = component.draftSettings as ReportAssetDistributionFetchFragment
  const { data: listData } = useListSimpleQuery({
    variables: {id: settings.list?.id || 0},
    skip: !settings || !settings.list?.id,
    errorPolicy: "all",
  })
  if(!settings){
    return(
      <></>
    )
  }

  const ownedComponent = component.owner?.id === user?.person?.id
  let infoColumns = [...ReportEditInfoFields(handleInputChange, {resetDates: true}),  ...ReportEditInfoTotalField(listData?.list || undefined)]
  return (
    <>
      <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={"Asset Distribution"}
        componentType={ComponentType.AssetDistribution}
        reportId={reportId}
        handleInputChange={handleInputChange}
      />
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <ReportEditInstances component={component}/>
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      />
      <AdditionalReportingEdit
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"table"}
        retractable={true}
        fields={AssetDistributionTableFields(settings)}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"footnotes"}
        retractable={true}
        fields={AssetDistributionFootnoteFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
    </>
  )
}

export const AssetDistributionExportSettings = (props: AggregatedExportSettingsProps): ComponentOverrideSettings =>  {
  const { component } = props
  const reportState = props.reportState as componentState
  const tab = reportState?.tab
  const openItems = reportState?.openItems || []

  let exportSettings = {
    componentId: component?.id,
    selectedDate: tab?.value,
    expandedRows: openItems.map((id) => id.toString()),
    assetDistributionPeriod: AssetDistributionPeriod.Quarterly
  } as AssetDistributionComponentOverrideSettings

  if(!tab?.primary){
    exportSettings["assetDistributionPeriod"] = AssetDistributionPeriod.Monthly
  }

  return {assetDistribution: [exportSettings]}
}

export default AssetDistribution