import { cloneDeep, compact, get, groupBy, isUndefined, keys, lowerFirst, map, remove, sortBy, uniq } from "lodash"
import { ClientPortfolioDetailComponentSettingsFragment, ClientPortfolioDetailLayoutFragment, ComponentApprovalCode, ComponentType, LayoutSectionType, Maybe, ReportAssetDistributionClientPortfolioFragment, ReportingPerformancePeriod, ReportSimpleListMemberFragment, ReportsListClientPortfolioFragment, ReportsListFragment, ReportsListMemberFragment } from "../__generated__/graphql"
import { AssetDistributionRowWithChildren } from "../Components/Report/Components/AssetDistribution"
import { DraggingComponentProps } from "../Components/Report/Shared/ReportComponent"
import { listExpanded, typeIdMapping } from "./list"
import { convertLookupToString } from "./object"

export type listReportExpanded = {
  name?: string
  subTitle?: string
  id: number | number
  uniqId: string
  subGroup?: listReportExpanded[]
  subGroupId?: number
  portfolio?: ReportsListClientPortfolioFragment
  draftLayout?: ClientPortfolioDetailLayoutFragment
  liveLayout?: ClientPortfolioDetailLayoutFragment
  currentHierarchy?: number[]
  approvalCount?: {[key in ComponentApprovalCode]?: number}
  groupId?: number
  unused?: boolean
  order?: number
  group?: number
}

export type CombinedListExpanded = listExpanded | listReportExpanded

export type ExpandReportOptions = {
  appendUnusedGroups?: boolean
  excludeList?: ReportsListFragment
}

export type RecursiveOptions = {
  unused?: boolean
}

// Convert the data from a list to a tree with correct ordering
export const expandReportList = (list:ReportsListFragment, options?: ExpandReportOptions) => {
  const appendUnusedGroups = options?.appendUnusedGroups || false
  let groupedLists = groupBy(list.items, "group")
  let usedGroups = ["1"]

  const recursivelyCombineGroups = (group:ReportsListMemberFragment[], currentHierarchy?: number[], recursiveOptions?: RecursiveOptions):listReportExpanded[] => {
    const unused = recursiveOptions?.unused || false
    return compact(sortBy(group, "order").map((groupMember) => {
      let approvalCount:{[key in ComponentApprovalCode]?: number } = {}
      groupMember.draftLayout?.sections?.forEach(section => {
        section?.components?.forEach(component => {
          if (component?.approval?.code) {
            approvalCount[component.approval.code] = (approvalCount[component.approval.code] || 0) + 1
          }
        })
      })
      if(groupMember.item?.__typename === "ClientPortfolio"){
        return {
          name: groupMember.item.name || "",
          subTitle: groupMember.item.dataType?.value || "",
          id: groupMember.item.id,
          uniqId: `${groupMember.type}:${groupMember.item.id}`,
          portfolio: groupMember.item,
          draftLayout: groupMember.draftLayout || undefined,
          liveLayout: groupMember.liveLayout || undefined,
          currentHierarchy: currentHierarchy,
          order: groupMember.order || undefined,
          group: groupMember.group || undefined,
          approvalCount,
          unused: unused,
        }
      } else if (groupMember.item?.__typename === "ListGroup" && !currentHierarchy?.includes(groupMember.item.id)) {
        const fetchedGroup = groupedLists[groupMember.item.id]
        usedGroups.push(groupMember.item.id.toString())
        let combinedGroup = recursivelyCombineGroups(fetchedGroup, [...(currentHierarchy || []), groupMember.item.id])
        const combinedApprovalCount:{[key in ComponentApprovalCode]?: number } = combinedGroup.reduce((approvalCount, groupMember) => {
          const groupApprovalCount = groupMember.approvalCount || {}
          Object.keys(groupApprovalCount).forEach((key) => {
            const keyName = key as ComponentApprovalCode
            approvalCount[keyName] = (approvalCount[keyName] || 0) + (groupApprovalCount[keyName] || 0)
          })
          return approvalCount
        }, {} as {[key in ComponentApprovalCode]?: number})
        const firstMember = combinedGroup.shift()
        if(firstMember){
          return {
            name: firstMember.name,
            id: firstMember.id,
            uniqId: firstMember.uniqId,
            portfolio: firstMember.portfolio,
            subTitle: firstMember.subTitle,
            draftLayout: firstMember.draftLayout,
            liveLayout: firstMember.liveLayout,
            subGroup: combinedGroup,
            subGroupId: groupMember.item.id,
            currentHierarchy: currentHierarchy,
            approvalCount: combinedApprovalCount,
            groupId: groupMember.item.id,
            order: groupMember.order || undefined,
            group: groupMember.group || undefined,
            unused: firstMember.unused,
          }
        }
      } else if (groupMember.item?.__typename === "ListHeader") {
        return {
          name: groupMember.item.text || "",
          id: groupMember.item.id,
          uniqId: `${groupMember.type}:${groupMember.item.id}`,
          order: groupMember.order || undefined,
          group: groupMember.group || undefined,
          approvalCount,
          currentHierarchy: currentHierarchy,
        }
      } else if (groupMember.item?.__typename === "ReportPage") {
        return {
          name: groupMember.item.pageName || "",
          id: groupMember.item.id,
          uniqId: `${groupMember.type}:${groupMember.item.id}`,
          order: groupMember.order || undefined,
          group: groupMember.group || undefined,
          approvalCount,
          currentHierarchy: currentHierarchy,
        }
      }
      return undefined
    }))
  }
  // Add this in to make the whole thing join up correctly
  let returnedValue = recursivelyCombineGroups(groupedLists["1"], [])
  const allKeys = Object.keys(groupedLists)
  let unusedGroups = allKeys.filter((key) => !usedGroups.includes(key))
  if(appendUnusedGroups && unusedGroups.length > 0){
    let unusedValues = []
    while(unusedGroups.length > 0){
      const currentGroup = unusedGroups.shift()
      if(currentGroup){
        usedGroups.push(currentGroup)
        let combinedGroup = recursivelyCombineGroups(groupedLists[currentGroup], [-1], {unused: false})
        unusedValues.push(...combinedGroup)
      }
      // Update unusedGroups so that if there are any recursive groups they are not added again
      unusedGroups = allKeys.filter((key) => !usedGroups.includes(key))
    }
    const combinedApprovalCount:{[key in ComponentApprovalCode]?: number } = unusedValues.reduce((approvalCount, groupMember) => {
      const groupApprovalCount = groupMember.approvalCount || {}
      Object.keys(groupApprovalCount).forEach((key) => {
        const keyName = key as ComponentApprovalCode
        approvalCount[keyName] = (approvalCount[keyName] || 0) + (groupApprovalCount[keyName] || 0)
      })
      return approvalCount
    }, {} as {[key in ComponentApprovalCode]?: number})
    let unorderedExpanded:listReportExpanded = {
      name: "Unordered Portfolios",
      id: -1,
      uniqId: "ReportPage:-1",
      subGroup: unusedValues,
      currentHierarchy: [],
      approvalCount: combinedApprovalCount,
      // unused: true,
    }
    returnedValue.push(unorderedExpanded)
  }
  if(options?.excludeList?.items && options.excludeList.items?.length > 0){
    let excludeExpanded:listReportExpanded = {
      name: "Excluded Portfolios",
      id: -2,
      uniqId: "ReportPage:-2",
      subGroup: recursivelyCombineGroups(options.excludeList.items, []),
      currentHierarchy: [],
      // unused: true,
    }
    returnedValue.push(excludeExpanded)
  }
  return returnedValue
}

// Remove selected total then convert rest of data to a tree
export const splitReportList = (inList: ReportsListFragment, totalFundPid?: number) => {
  let list = cloneDeep(inList)
  const totalData = remove(list?.items || [], (listData) => {
    if(listData.item?.__typename === "ClientPortfolio"){
      const portfolio = listData.item as ReportAssetDistributionClientPortfolioFragment | undefined
      return portfolio?.id === totalFundPid
    }
    return false
  }).map((listData) => {
    const portfolio = listData.item as ReportAssetDistributionClientPortfolioFragment
    return {
      name: portfolio.name || "",
      subTitle: portfolio.dataType?.value || "",
      id: portfolio.id,
      portfolio: portfolio,
    }
  })
  let mappedData = expandReportList(list)
  return [mappedData, totalData] as [listReportExpanded[], listReportExpanded[]]
}

// Return List of ids of all expandable rows
export const expandableReportList = (list: (CombinedListExpanded)[], currentList: (number | string)[] = []):(number | string)[] => {
  return compact(uniq(list?.flatMap((listItem) => {
    if(listItem.subGroup && !currentList.includes(listItem.id)){
      return expandableReportList(listItem.subGroup, [...currentList, listItem.id])
    }
    return currentList
  })))
}

// Return List of ids that match search criteria and their parents
export const expandableReportListSearch = (list: (CombinedListExpanded)[], search: string, currentList: (number | string)[] = []):(number | string)[] => {
  return compact(uniq(list.flatMap((listItem) => {
    if(!currentList.includes(listItem.id)){
      let updatedList:(number | string)[] = []
      if(listItem.subGroup){
        updatedList = expandableReportListSearch(listItem.subGroup, search, [...currentList, listItem.id])
      }
      if(updatedList.length > 0 || listItem.name?.toLowerCase().includes(search.toLowerCase()) || listItem.id?.toString().toLowerCase().includes(search.toLowerCase())){
        return [...updatedList, listItem.id]
      }
    }
    return []
  })))
}

// Return List of groupIds of all expandable rows
export const expandableReportGroupList = (list: listReportExpanded[], currentList: number[] = []):number[] => {
  return compact(uniq(list.flatMap((listItem) => {
    if(listItem.subGroup && !currentList.includes(listItem.groupId || -1)){
      return expandableReportGroupList(listItem.subGroup, compact([...currentList, listItem.groupId]))
    }
    return currentList
  })))
}

// Return list of all row ids in data
export const getAllAssetDistributionRowIds = (rows:AssetDistributionRowWithChildren[]) : number[]=> {
  return compact(uniq(rows.flatMap((row) => {
    return [parseInt(row.rowId, 10), ...getAllAssetDistributionRowIds(compact(row?.children || []))]
  })))
}

export const recursivelyOrderReport = (lists: (CombinedListExpanded)[], openItems?: (number|string)[]):(CombinedListExpanded)[] => {
  return lists?.reduce((listArray:(CombinedListExpanded)[], listItem) => {
    if(listItem.subGroup && (!openItems || openItems.includes(listItem.id))){
      return [...listArray, listItem, ...recursivelyOrderReport(listItem.subGroup, openItems)]
    }
    return [...listArray, listItem]
  }, [])
}

// get the items that need to be open for the targetId to be visible
export const getExpandableReportLocation = (list: (CombinedListExpanded)[], targetId?: number, currentList: (number | string)[] = []) => {
  let returnValue:(number | string)[] = []
  list?.forEach((listItem) => {
    if(listItem.id === targetId){
      returnValue = [...currentList, listItem.id]
    } else if (listItem.subGroup && !currentList.includes(listItem.id) && !returnValue.length){
      returnValue = getExpandableReportLocation(listItem.subGroup, targetId, [...currentList, listItem.id])
    }
  })
  return returnValue
}

interface simplifiedRows {
  rowId: number | string
  children?: Maybe<simplifiedRows>[] | null
}

export const expandableDataList = (list: (Maybe<simplifiedRows>)[], currentList: (number | string)[] = []):(number | string)[] => {
  return compact(uniq(list.flatMap((listItem: Maybe<simplifiedRows>) => {
    if(listItem?.children && !currentList.includes(listItem.rowId)){
      return expandableDataList(listItem.children, [...currentList, listItem.rowId])
    }
    return currentList
  })))
}

// update list item with function
export const updateReportItem = (items:ReportSimpleListMemberFragment[], uniqId: string, updateFunction: (item:ReportSimpleListMemberFragment) => ReportSimpleListMemberFragment | undefined):ReportSimpleListMemberFragment[] => {
  const [type, id] = uniqId.split(":")

  return compact(map(items, (item) => {
    if(item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id){
      return updateFunction(item)
    }
    return item
  }))
}

export const convertComponentForMutation = (componentSettings?: ClientPortfolioDetailComponentSettingsFragment, componentType?: Maybe<ComponentType>) => {
  let patchList = convertLookupToString(
    componentSettings || {},
    false,
    ["groupPerformanceType", "vehiclePerformanceType"],
    ["List", "Client", "ClientPortfolio", "Group", "Index"],
    { "target.targetId": "target" }
  )

  if ("vehicles" in patchList) patchList.vehicles = compact(patchList?.vehicles?.map((vehicle:any) => vehicle?.vehicle?.id || vehicle?.vehicle?.fundid))

  keys(patchList).forEach(property => {
    const value = patchList[property]
    if (!value) {
      // Set default values for when things are not set
      // Mostly used when new required fields are added but not set in the database
      if(property === "monthlyOptions"){
        patchList[property] = {show: false, dates: [], __typename: "MonthlyOptions"}
      } else if (property === "reportingPerformancePeriod"){
        patchList[property] = ReportingPerformancePeriod.Trailing
      }
    }

    if (!isUndefined(value) && property.startsWith(lowerFirst(componentType || "")) && property !== "text") {
      // For when properties are overloaded so they are renamed in the query using the name of the component
      const newKey = lowerFirst(property.substr(lowerFirst(componentType || "").length))

      patchList[newKey] = value
      delete patchList[property]
    }
  })

  return patchList
}


type relocateComponentProps = {
  setEditedDraftLayout?: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  relocatedIdentifier?: DraggingComponentProps
  newLocation?: {section?: number, component?: number}
}

const relocateComponentInSection = (
  newLayout: ClientPortfolioDetailLayoutFragment,
  relocatedIdentifier: DraggingComponentProps,
  newLocation: { section?: number, component?: number }
) => {
  let fromSection
  if(relocatedIdentifier.sectionId !== undefined){
    fromSection = newLayout.sections?.[relocatedIdentifier.sectionId]
  } else {
    fromSection = newLayout.sections?.find((section) =>
      section?.components?.some((component) => component?.id === relocatedIdentifier.id)
    )
  }

  if (fromSection?.components) {
    const component = fromSection.components.find((component) => component?.id === relocatedIdentifier.id)
    if (component && newLayout.sections && newLocation.section !== undefined) {
      remove(fromSection.components, (component) => component?.id === relocatedIdentifier.id)
      if(newLocation.component === undefined){
        const newSection = {
          components: [component],
          type: LayoutSectionType.SingleColumnSection,
          __typename: "LayoutSection" as "LayoutSection",
        }
        newLayout.sections.splice(newLocation.section, 0, newSection)
      } else {
        const newSection = newLayout.sections[newLocation.section]
        newSection?.components?.splice(newLocation.component, 0, component)
      }
    }
  }
}

const relocateSection = (
  newLayout: ClientPortfolioDetailLayoutFragment,
  relocatedIdentifier: DraggingComponentProps,
  newLocation: { section?: number, component?: number }
) => {
  const section = newLayout.sections?.[relocatedIdentifier.id]

  if (section && newLocation.section !== undefined) {
    const usedSection = newLayout.sections?.[newLocation.section]
    newLayout.sections?.splice(relocatedIdentifier.id, 1)
    let newLocationSection = newLocation.section
    if(newLocation.component !== undefined && section.components?.length === 1 && usedSection){
      const component = section.components[0]
      usedSection?.components?.splice(newLocation.component || 0, 0, component)
    } else {
      // minus 1 if the new location is after the relocated section because of the splice
      if (newLocation.section > relocatedIdentifier.id) {
        newLocationSection -= 1
      }
      newLayout.sections?.splice(newLocationSection, 0, section)
    }
  }
}

export const relocateComponent = (props: relocateComponentProps) => {
  const { setEditedDraftLayout, relocatedIdentifier, newLocation } = props

  if (setEditedDraftLayout && relocatedIdentifier && newLocation) {
    setEditedDraftLayout((editedDraftLayout) => {
      if (!editedDraftLayout) return undefined

      const newLayout = cloneDeep(editedDraftLayout)

      if (relocatedIdentifier.type === 'component') {
        relocateComponentInSection(newLayout, relocatedIdentifier, newLocation)
      } else if (relocatedIdentifier.type === 'section') {
        relocateSection(newLayout, relocatedIdentifier, newLocation)
      }

      return newLayout
    })
  }
  relocatedIdentifier?.ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}