import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { default as React } from 'react'
import { Row } from 'reactstrap'

import { ComponentType, LayoutSectionType, ReportTextFetchFragment } from '../../../__generated__/graphql'
import RichTextEditor from '../../ui/Editor'
import { ApprovalsReportDisplay } from '../Shared/ReportApprovals'
import { AggregatedComponentProps, BaseComponent, IndividualComponentProps, TemplateComponent, ToolbarProps, ReportDisplayType } from '../Shared/ReportComponent'
import { AggregatedReportEditProps, ReportEditHeading, ReportEditInfoFields, ReportEditInstances, ReportEditTemplate, SimpleReportEdit } from '../Shared/ReportEdit'

const Text: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportTextFetchFragment>
      expectedTypename={"Text"}
      reactComponent={TextDisplay}
      {...props}
    />
  )
}

const TextDisplay: React.FC<IndividualComponentProps<ReportTextFetchFragment>> = ({component, auth, settings, selected, handleSelect, editMode, report, sectionNumber, setEditedDraftLayout, editedDraftLayout, componentNumber, setSelectedComponentId, view, portfolioId}) => {
  const name = component.name || ""

  let tooltipProps:ToolbarProps | undefined

  if(componentNumber === -1){
    tooltipProps = {'exportOptions': {
      name: name,
      slides: [{
        title: name,
        sections: [{
          components: [component.id],
          type: LayoutSectionType.SingleColumnSection
        }]
      }],
      settings: {
        live: view === ReportDisplayType.Live,
        footerName: report.client?.name,
      }
    },
    }
  }

  return (
    <TemplateComponent
      name={name}
      componentTypeName='Text'
      // rightText={`As of ${asOfDate}`}
      // tabs={settings?.monthlyOptions?.show ? tabs : undefined}
      // currentTab={tab}
      // onTabChange={(tab) => setTab(tab)}
      selected={selected}
      tooltipProps={tooltipProps}
      onClick={handleSelect}
      editMode={editMode}
      sectionNumber={sectionNumber}
      report={report}
      setEditedDraftLayout={setEditedDraftLayout}
      editedDraftLayout={editedDraftLayout}
      componentNumber={componentNumber}
      componentId={component.id}
      setSelectedComponentId={setSelectedComponentId}
      view={view}
      auth={auth}
      hideTitle={true}
      portfolioId={portfolioId}
    >
      <Row className="no-gutters">
        <p className="intro" dangerouslySetInnerHTML={{__html: settings.text || "Text will appear here."}}></p>
      </Row>
    </TemplateComponent>
  )
}

export const TextEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, reportId}) => {
  const settings = component.draftSettings as ReportTextFetchFragment

  const ownedComponent = component.owner?.id === user?.person?.id
  let infoColumns = [...ReportEditInfoFields(handleInputChange, {hideDate: true, hideList: true, hideName: true})]
  return (
    <>
      <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={"Text"}
        componentType={ComponentType.Text}
        reportId={reportId}
        handleInputChange={handleInputChange}
      />
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <ReportEditInstances component={component}/>
      {/* <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      /> */}
      <ReportEditTemplate name={"Content"} retractable={true}>
        <RichTextEditor
          key={"text-component"}
          identifier={"text-component"}
          value={settings.text || ""}
          editMode={true}
          updateValue={value => {
            handleInputChange(
              value,
              "text"
            )
          }}
          // onFocus={() => handleFocus()}
          stopAutoFocus={true}
          toolbarOnFocus={true}
          placeholder={"Content"}
          expandable={true}
          debounceInput={true}
        />
      </ReportEditTemplate>
    </>
  )
}

export default Text